var listener = new BroadcastChannel('ptnw.net');
listener.onmessage = function (e) {

    // console.log(e);

    if (e.data.type == 'reload-authorization') {

        let token = getCookie('_q');

        if (!token){
            console.log('User is not logged in');
            return;
        }

        let endpoint = 'https://api.ptnw.net/v2/auth/get-permissions';

        if (/^(.+):\/\/localhost(.+)$/.test(document.URL)){
            endpoint = 'http://localhost:8080/v2/auth/get-permissions';
        }

        let xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (xhr.readyState == 4) {

                let response = JSON.parse(xhr.responseText);

                localStorage[e.data.module] = response.result.token;
            }
        }
        xhr.open('POST', endpoint);
        xhr.setRequestHeader("Content-type", "application/json");
        xhr.setRequestHeader("token", token);
        xhr.send(JSON.stringify({ module: e.data.module }));

    } else if (e.data.type == 'logout') {

        // begin clear cookies
        document.cookie.split(";").forEach(function (c) { document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/"); });
        // end clear cookies

        localStorage.clear();

        // begin delete database
        var req = indexedDB.deleteDatabase('DatabaseNds');
        req.onsuccess = function () {
            console.log("Deleted database successfully");
        };
        req.onerror = function () {
            console.log("Couldn't delete database");
        };
        req.onblocked = function () {
            console.log("Couldn't delete database due to the operation being blocked");
        };
        // end delete database

        window.location.href = '/';
    }

};

window.getCookie = function (name) {
    var match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
}